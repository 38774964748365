import { translationsContent } from "../../../localization/translations";
import { useLanguage } from "../../../store/lang.context";
import { translate } from "../../../utils/translate";
import Button from "../../ui/button/SimpleButton";
import DynamicModal from "../../ui/modal/DynamicModal";
import classes from "./popup.module.scss";

interface Props {
  onClose: () => void;
}

const Popup = ({ onClose }: Props) => {
  const { language } = useLanguage();
  const message = translate(
    translationsContent,
    "partnerCompanyDeliveryMessage",
    language
  );

  return (
    <DynamicModal onClose={onClose}>
      <div className={classes.container}>
        <p className={classes.message}>{message}</p>
        <div className={classes.button}>
          <Button
            label={`${translate(translationsContent, "close", language)}`}
            onClick={onClose}
          />
        </div>
      </div>
    </DynamicModal>
  );
};

export default Popup;
