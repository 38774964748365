import { useMediaQuery } from "@mui/material";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";

import { GeneralTranslationsType } from "../../api/types/translations";
import { translationsContent } from "../../localization/translations";
import { useLanguage } from "../../store/lang.context";
import { useRestaurants } from "../../store/restaurants.context";
import { useTakeawaySelector } from "../../store/takeawaySelector.context";
import imageLoader from "../../utils/loader";
import { translate } from "../../utils/translate";
import { Position } from "../Menu/Restaurants";
import Takeaway from "../Takeaway";
import SearchInput from "../ui/input/SearchInput/input";
import DeliveryModal from "./DeliveryModal";
import classes from "./homepage.module.scss";
import Places from "./Places/index";

const HomePageVideo = dynamic(() => import("./Video"), { ssr: false });
import { useDebounce } from "../../hooks/useDebounce";
import { getHereLocationData, getHerePredictions } from "../../utils/here";
import Popup from "./Popup";

type HomepageSearchInputType = {
  restaurantSearch: string;
  addressSearch: string;
};

export type DeliveryLinks = {
  delivero: string;
  uberEats: string;
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: [GeneralTranslationsType];
}

const HomePage = ({ translations }: Props) => {
  const [addressSearchOpen, setAddressSearchOpen] = useState(false);
  const [position, setPosition] = useState<Position>({ lat: 0, lng: 0 });
  const [openPredictions, setOpenPredictions] = useState(false);
  const [deliveryModalIsOpen, setDeilveryModalIsOpen] = useState(false);
  const [deliveryLinks, setDeliveryLinks] = useState<DeliveryLinks>({
    delivero: "",
    uberEats: "",
  });
  const [isPartnerDeliveryPopupOpen, setIsPartnerDeliveryPopupOpen] =
    useState(false);

  const [searchedRestaurantName, setSearchedRestaurantName] = useState("");
  const [placeId, setPlaceId] = useState<string>();
  const restaurantNameDebounced = useDebounce(searchedRestaurantName, 500);

  const { restaurants } = useRestaurants();
  const isMobile = useMediaQuery("(max-width: 750px)");
  const { language } = useLanguage();

  const { takeawaySelector, setTakeawaySelector } = useTakeawaySelector();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (
    identifier: keyof HomepageSearchInputType,
    value: string
  ) => {
    if (value.length >= 2) setSearchedRestaurantName(value);
  };

  const handleOpenPartnerDeliveryPopup = () => {
    setIsPartnerDeliveryPopupOpen(true);
  };

  const handleClosePartnerDeliveryPopup = () => {
    setTakeawaySelector((prev) => ({
      ...prev,
      isTakeawayModalOpen: true,
    }));
    setAddressSearchOpen(false);
    setDeilveryModalIsOpen(false);
    setIsPartnerDeliveryPopupOpen(false);
  };

  const handleSetLatLng = async (placeId: string) => {
    const { lat, lng } = await getHereLocationData(placeId);
    if (lat && lng)
      setPosition({
        lat,
        lng,
      });
  };

  useEffect(() => {
    if (restaurantNameDebounced.length >= 2) {
      getHerePredictions(restaurantNameDebounced).then((res) => {
        setPlaceId(res);
      });
    }
  }, [restaurantNameDebounced]);

  useEffect(() => {
    if (!!placeId) {
      setOpenPredictions(true);
      handleSetLatLng(placeId);
    } else {
      setOpenPredictions(false);
    }
  }, [placeId]);

  return (
    <>
      <div>
        <div
          className={classes.intro}
          style={{
            backgroundImage: `url('${
              translations[0].main_background_image ??
              "/assets/ui/homepage1.jpg"
            }')`,
          }}
        >
          <div className={classes.innerContainer}>
            {!isMobile && <HomePageVideo />}
            {/* <div className={classes.content}>
              <p className={classes.header}>
                {translate(translationsContent, "orderNow", language)} :
              </p>
            </div> */}
            <div className={classes.content}>
              <div
                className={classes.item}
                onClick={() => {
                  setTakeawaySelector((prev) => ({
                    ...prev,
                    isTakeawayModalOpen: !prev.isTakeawayModalOpen,
                  }));
                  setAddressSearchOpen(false);
                }}
              >
                <Image
                  loader={imageLoader}
                  width="25px"
                  height="25px"
                  alt="shopping bag"
                  src="shoppingBag.png"
                />
                <p>{translate(translationsContent, "toTakeaway", language)}</p>
              </div>
              <div
                className={classes.item}
                onClick={() => {
                  setAddressSearchOpen(!addressSearchOpen);
                  setOpenPredictions(false);
                }}
              >
                <Image
                  loader={imageLoader}
                  width="25px"
                  height="25px"
                  alt="shopping bag"
                  src="bike.png"
                />
                <p>{translate(translationsContent, "delivery", language)}</p>
              </div>
            </div>
            {addressSearchOpen && (
              <div className={classes.addresses}>
                <SearchInput<HomepageSearchInputType, "addressSearch">
                  placeholder={`${translate(
                    translationsContent,
                    "deliverySearchPlaceholder",
                    language
                  )}`}
                  identifier="addressSearch"
                  onChange={handleInputChange}
                  inputRef={inputRef}
                />
                {!!placeId && openPredictions && (
                  <Places
                    restaurants={restaurants}
                    position={position}
                    inputRef={inputRef}
                    deliveryModal={setDeilveryModalIsOpen}
                    setDeliveryLinks={setDeliveryLinks}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {/* <Introduction translations={translations} />
        <FoodTypes translations={translations} />
        <Fidelity translations={translations} />
        <RestaurantsInformation translations={translations} />
        <Franchise translations={translations} /> */}
      </div>
      {deliveryModalIsOpen && (
        <DeliveryModal
          setModalIsOpen={setDeilveryModalIsOpen}
          deliveroLink={deliveryLinks.delivero}
          uberEatsLink={deliveryLinks.uberEats}
          handleOpenPartnerDeliveryPopup={handleOpenPartnerDeliveryPopup}
        />
      )}
      {takeawaySelector.isTakeawayModalOpen && <Takeaway />}
      {isPartnerDeliveryPopupOpen && (
        <Popup onClose={handleClosePartnerDeliveryPopup} />
      )}
    </>
  );
};

export default HomePage;
