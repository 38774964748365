import Image from "next/image";
import React, { useMemo } from "react";

import { translationsContent } from "../../../localization/translations";
import { useAppSelector } from "../../../redux/hooks";
import { selectRestaurant } from "../../../redux/slices/restaurantSlice";
import { useLanguage } from "../../../store/lang.context";
import { useRestaurants } from "../../../store/restaurants.context";
import imageLoader from "../../../utils/loader";
import { translate } from "../../../utils/translate";
import Button from "../../ui/button/SimpleButton";
import DynamicModal from "../../ui/modal/DynamicModal/index";
import classes from "./deliveryModal.module.scss";

interface Props {
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deliveroLink: string;
  uberEatsLink: string;
  handleOpenPartnerDeliveryPopup: VoidFunction;
}

const restaurantIdsWithExternalDelivery = [26, 84, 93, 95];

const DeliveryModal = ({
  deliveroLink,
  uberEatsLink,
  setModalIsOpen,
  handleOpenPartnerDeliveryPopup,
}: Props) => {
  const { language } = useLanguage();
  const handleOnClose = () => {
    setModalIsOpen(false);
  };
  const { restaurants } = useRestaurants();
  const restaurant = useAppSelector(selectRestaurant);
  const hasUberEats = restaurants.some(
    (res) =>
      res.id === restaurant.id &&
      res.order_urls.some((url) =>
        url.delivery_service.name.includes("Uber Eats")
      )
  );
  const hasDeliveroo = restaurants.some(
    (res) =>
      res.id === restaurant.id &&
      res.order_urls.some((url) =>
        url.delivery_service.name.includes("Deliveroo")
      )
  );

  const hasExternalDelivery = useMemo(
    () => restaurantIdsWithExternalDelivery.find((id) => id === restaurant.id),
    [restaurant.id]
  );

  return (
    <DynamicModal onClose={handleOnClose} contentScroll={true}>
      <div className={classes.container}>
        <h2>{translate(translationsContent, "selectRestaurant", language)}</h2>
        <div className={classes.innerContainer}>
          {hasDeliveroo && (
            <div className={classes.column}>
              <div className={classes.image}>
                <Image
                  loader={imageLoader}
                  width="60px"
                  height="60px"
                  src="Deliveroo.png"
                  alt="deliveroo"
                />
              </div>
              <a href={deliveroLink} target="_blank" rel="noreferrer">
                <div className={classes.button}>
                  <Button
                    label={`${translate(
                      translationsContent,
                      "deliverySelection",
                      language
                    )}`}
                  />
                </div>
              </a>
            </div>
          )}
          {hasUberEats && (
            <div className={classes.column}>
              <div className={classes.image}>
                <Image
                  loader={imageLoader}
                  width="60px"
                  height="60px"
                  src="Ubereats.png"
                  alt="uber eats"
                />
              </div>
              <a href={uberEatsLink} target="_blank" rel="noreferrer">
                <div className={classes.button}>
                  <Button
                    label={`${translate(
                      translationsContent,
                      "deliverySelection",
                      language
                    )}`}
                  />
                </div>
              </a>
            </div>
          )}
          {hasExternalDelivery && (
            <div className={classes.column}>
              <Image
                loader={imageLoader}
                width="110px"
                height="30px"
                src="eatsaladLogo.svg"
                alt="EatSalad"
              />
              <p>
                {translate(
                  translationsContent,
                  "partnerCompanyDelivery",
                  language
                )}
              </p>
              <div className={classes.button}>
                <Button
                  label={`${translate(
                    translationsContent,
                    "deliverySelection",
                    language
                  )}`}
                  onClick={handleOpenPartnerDeliveryPopup}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </DynamicModal>
  );
};
export default DeliveryModal;
