import React, { RefObject, useEffect, useState } from "react";

import { RestaurantItemType } from "../../../api/types/restaurant.type";
import { useAppDispatch } from "../../../redux/hooks";
import { setRestaurantInfo } from "../../../redux/slices/restaurantSlice";
import { Position } from "../../Menu/Restaurants";
import { DeliveryLinks } from "..";
import classes from "./item.module.scss";

interface Props {
  restaurants: RestaurantItemType[];
  position: Position;
  inputRef: RefObject<HTMLInputElement>;
  deliveryModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDeliveryLinks: React.Dispatch<React.SetStateAction<DeliveryLinks>>;
}

const Places = ({
  restaurants,
  position,
  inputRef,
  deliveryModal,
  setDeliveryLinks,
}: Props) => {
  const [filteredRestaurants, setFilteredRestaurants] =
    useState<RestaurantItemType[]>(restaurants);

  const dispatch = useAppDispatch();

  const handleOnClick = () => {
    deliveryModal(true);
  };

  const selectRestaurant = (restaurant: RestaurantItemType) => {
    const {
      id,
      name,
      addresse,
      opening_hours: openingHours,
      closing_rules: closingRules,
    } = restaurant;
    setDeliveryLinks({
      delivero:
        restaurant.order_urls.find((order_url) =>
          order_url.delivery_service.name.includes("Deliveroo")
        )?.url ?? "",
      uberEats:
        restaurant.order_urls.find((order_url) =>
          order_url.delivery_service.name.includes("Uber")
        )?.url ?? "",
    });
    dispatch(
      setRestaurantInfo({
        id,
        name,
        address: addresse ?? "",
        openingHours,
        closingRules,
      })
    );
    handleOnClick();
  };

  const sqDist = (lat: number, lng: number) => {
    return (position?.lat - lat) ** 2 + (position.lng - lng) ** 2;
  };

  useEffect(() => {
    if (filteredRestaurants && position.lat !== 0 && position.lng !== 0) {
      filteredRestaurants.sort((a, b) => {
        return (
          sqDist(a.latitude ? a.latitude : 0, a.longitude ? a.longitude : 0) -
          sqDist(b.latitude ? b.latitude : 0, b.longitude ? b.longitude : 0)
        );
      });
    }
    if (inputRef.current) {
      if (inputRef.current.value && inputRef.current?.value.length >= 3) {
        const value = inputRef.current.value + "";
        const tmp: RestaurantItemType[] = restaurants.filter((item) =>
          item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        );
        if (tmp.length > 0) {
          let tmp2: RestaurantItemType[] = filteredRestaurants;
          tmp.forEach(
            (item) => (tmp2 = tmp2.filter((item2) => item2.name !== item.name))
          );
          const tmp3: RestaurantItemType[] = [...tmp, ...tmp2];
          setFilteredRestaurants(tmp3);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current?.value]);

  return (
    <div className={classes.list}>
      {filteredRestaurants
        .filter((restaurant) => restaurant.order_urls.length)
        .map((restaurant, i) => (
          <p
            key={i}
            className={classes.item}
            onClick={() => {
              selectRestaurant(restaurant);
            }}
          >
            {restaurant.name}
            {", "}
            {restaurant.addresse}
          </p>
        ))}
    </div>
  );
};

export default Places;
