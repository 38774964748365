import Image from "next/image";
import { RefObject } from "react";

import imageLoader from "../../../../utils/loader";
import classes from "./search.module.scss";

interface Props<T, K extends keyof T> {
  placeholder: string;
  identifier: K;
  onChange: (identifier: K, value: string) => void;
  inputRef: RefObject<HTMLInputElement>;
}

const SearchInput = <T, K extends keyof T>({
  placeholder,
  onChange,
  identifier,
  inputRef,
}: Props<T, K>) => {
  return (
    <div className={classes.container}>
      <input
        type="text"
        placeholder={placeholder}
        onChange={(event) => {
          onChange(identifier, event.target.value);
        }}
        ref={inputRef}
      />
      <div className={classes.image}>
        <Image
          loader={imageLoader}
          src="searchIcon.svg"
          width="25px"
          height="25px"
          alt="search"
        />
      </div>
    </div>
  );
};

export default SearchInput;
